// @codekit-prepend "utils.js"

jQuery(function ($) {

	/*-------------
	 *
	 *  isMobile
	 *
	 *-------------*/

	var isMobile = ($(window).innerWidth() < 1024) ? true : false;

	$(window).smartresize(function () {
		isMobile = ($(window).innerWidth() < 1024) ? true : false;
	});

	/*-------------
	 *
	 *  SCROLL TO
	 *
	 *-------------*/
	$('.scroll-to').on('click', function () {
		var scrollto = $(this).attr('data-scroll-to');
		var scrollOffset = $(this).attr('data-scroll-offset') ? $(this).attr('data-scroll-offset') : '0';
		if ($(scrollto).length) {
			var maxTime = isMobile ? 2000 : 3000;
			var pos = (parseInt($(scrollto).offset().top) + parseInt(scrollOffset));
			var currScroll = $(window).scrollTop();
			var distancia = pos - currScroll;
			if (distancia < 0) {
				distancia = distancia * -1;
			}
			var scrollTime = distancia * 2;
			if (scrollTime > maxTime) {
				scrollTime = maxTime;
			}
			if (scrollTime < 500) {
				scrollTime = 500;
			}
			$('body').addClass('is-animating');
			$('html, body').animate({
				scrollTop: pos
			}, scrollTime, 'easeInOutExpo', function () {
				$('body').removeClass('is-animating');
				if ($('body.menu-active').length) {
					$('body, html').removeClass('menu-active');
				}
			});
		}
	});

	/*--------------------------
	 *
	 *  Autocomplete off
	 *  class .autocomplete-off
	 *
	 *--------------------------*/

	$(window).on('load', function () {
		$('.autocomplete-off').attr('autocomplete', 'nope');
	});

	/*--------------------------
	 *
	 *  INPUT FOCUS - LABEL POSITIONING
	 *
	 *--------------------------*/
	$('body').on('focusin focusout change', 'input, textarea', function (e) {
		if (!$(this).attr('type') || $(this).attr('type') != 'checkbox') {
			var dis = $(this);
			var label = $(this).parents('.acf-field');
			if (e.type == 'focusin') {
				if (label.length) label.addClass('is-focus');
			} else {
				if (label.length && !dis.val()) label.removeClass('is-focus');
			}
			if (e.type == 'change') {
				if (dis.val()) {
					label.addClass('is-completed');
				}
			}
		}
	});


	/*--------------------------
	 *
	 *  REVEAL TAB
	 *
	 *--------------------------*/
	$('.reveal-item').on('click', function (e) {
		var $dis = $(this);
		if ($(e.target).hasClass('reveal-title') || $(e.target).parent('.reveal-title').length) {
			$dis.toggleClass('is-open');
			if ($dis.hasClass('is-open')) {
				$dis.find('.reveal-content .content').slideDown('slow');
			} else {
				$dis.find('.reveal-content .content').slideUp('slow');
			}
		}

	});

	/*--------------------------
	 *
	 *  HEADSPACE
	 *
	 *--------------------------*/
	var headspace;
	// $(window).load(function () {
	// 	headspace = Headspace(document.querySelector('header.header'), {
	// 		startOffset: $('.header').innerHeight(),
	// 		tolerance: 10,
	// 		showAtBottom: false, // default: true
	// 		classNames: {
	// 			base: 'header-main',
	// 			fixed: 'is-fixed',
	// 			hidden: 'is-hidden'
	// 		}
	// 	});
	// });


	/*--------------------------
	 *
	 *  FOOTER
	 *
	 *--------------------------*/

	var footerHeight = $('.footer').innerHeight();
	$(window).on('load resize', function () {
		footerHeight = $('.footer').innerHeight();
		$('#main').css("margin-bottom", footerHeight + 'px');
	});

	/*--------------------------
	 *
	 *  PROGRESS
	 *
	 *--------------------------*/
	var winHeight = $(window).height(),
		docHeight = $(document).height(),
		progressBar = $('#progress'),
		max, value;

	/* Set the max scrollable area */
	max = docHeight - winHeight;
	//progressBar.attr('max', max);

	// $(document).on('scroll', function () {
	// 	value = $(window).scrollTop();
	// 	progressBar.attr('value', value);
	// });


	/*--------------------------
	 *
	 *  SOUNDCLOUD PLAYER
	 *
	 *--------------------------*/
	// settings
	// var clientId = 'nU21E2bvWDf1YkhHuchdIP5ioCC2QNBU';
	// var trackId = '682064651';

	// // DOM elements
	// var artwork = $("#player-cover img");
	// var band = $('#band');
	// var song = $('#track');
	// var audioPlayer;
	// var waveform;


	// // Init SoundCloud JS SDK (dont forget to include the .js SDK)
	// SC.initialize({
	// 	client_id: clientId
	// });

	// // Getting SC track infos
	// SC.get('/tracks/' + trackId, function (track) {

	// 	// Injecting infos
	// 	artwork.attr('src', track.artwork_url.replace('-large', '-crop')); // Cover (replacing the default image size "large" with "crop")
	// 	band.html(track.user.username); // Band Name
	// 	song.html(track.title); // Song name

	// 	// Play btn
	// 	var status = false; // play status
	// 	waveform = $('#waveform');
	// 	var wavesurfer;

	// 	var audioUrl = track.uri + '/stream?client_id=' + clientId;
	// 	audioPlayer = new Audio(track.uri + '/stream?client_id=' + clientId); // Create audio object with stream url...
	// 	wavesurfer = WaveSurfer.create({
	// 		container: '#waveform',
	// 		waveColor: '#D7D7D7',
	// 		progressColor: 'black',
	// 		barWidth: 2
	// 	});
	// 	wavesurfer.load(audioUrl);

	// 	$('#play').click(function (e) {
	// 		console.log("play", status);
	// 		e.preventDefault();

	// 		if (status == false) {

	// 			$(this).removeClass('fa-play').addClass('fa-pause'); // change play btn to pause btn

	// 			wavesurfer.play();
	// 			//audioPlayer.play(); // ...and play

	// 			status = !status; // invert player status  
	// 		} else {

	// 			$(this).removeClass('fa-play').addClass('fa-pause'); // change pause btn to play btn
	// 			//audioPlayer.pause(); // Pause the player
	// 			wavesurfer.pause();
	// 			status = !status; // invert status
	// 		}


	// 	});

	// });


	/*--------------------------
	 *
	 *  LOADER UNLOAD
	 *
	 *--------------------------*/

	var ignore_unload = false;

	// Prevent if link has mailto
	$('a[href^=mailto]').on('click', function () {
		ignore_unload = true;
	});

	$(window).on('beforeunload', function (e) {
		if (!ignore_unload) {
			$('#loader').fadeIn();
		}
		ignore_unload = false;
	});

	/*--------------------------
	 *
	 *  LOADER
	 *
	 *--------------------------*/
	$(window).on('load', function () {
		$('#loader').fadeOut(function () {
			if ($('.scroll-on-load').length) {
				var scrollto = $('.scroll-on-load');
				var scrollOffset = '-80';
				if ($(scrollto).length) {
					var maxTime = isMobile ? 2000 : 3000;
					var pos = (parseInt($(scrollto).offset().top) + parseInt(scrollOffset));
					var currScroll = $(window).scrollTop();
					var distancia = pos - currScroll;
					if (distancia < 0) {
						distancia = distancia * -1;
					}
					var scrollTime = distancia * 2;
					if (scrollTime > maxTime) {
						scrollTime = maxTime;
					}
					if (scrollTime < 500) {
						scrollTime = 500;
					}
					$('body').addClass('is-animating');
					$('html, body').animate({
						scrollTop: pos
					}, scrollTime, 'easeInOutExpo', function () {
						$('body').removeClass('is-animating');
					});
				}
			}
		});
	});


});
